.scanner_cam_container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
}

.scanner_cam {
    position: absolute;
    width: 100%;
    height: 100%;
}

.scanner_cam_overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 85%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.scanner_cam_overlay_bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 12.5%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.scanner_cam_button {
    color: white;
    font-size: 20px;
    margin: 0 10px;
}

.scanner_cam_button_cap {
    background-color: white;
    width: 60px;
    height: 60px;
    border-radius: 50px;
}

.scanner_cam_guide {
    flex: 1;
    background: rgba(0, 0, 0, 0.5);
  }

.scanner_cam_container {
    display: flex;
    align-items: stretch;
    z-index: 10;
}

.scanner_cam {
    flex: 8;
}
  
