.AuthPopUp{
    position: absolute;
    width: 95%;
    height: auto;
    background-color: white;
    z-index: 10;
    border-radius: 50px;
    animation: slideUp 0.5s ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    max-height: 96%;
    overflow-y: auto;
    padding-bottom: 10%;
  }

  .FeedbackPopUp{
    position: absolute;
    width: 95%;
    height: auto;
    background-color: white;
    z-index: 10;
    border-radius: 50px;
    animation: slideUp 0.5s ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    max-height: 96%;
    overflow-y: auto;
    padding-bottom: 10%;
  }

  .UserPopUp{
    position: absolute;
    width: 95%;
    height: auto;
    background-color: white;
    z-index: 10;
    border-radius: 50px;
    animation: slideUp 0.5s ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    max-height: 96%;
    overflow-y: auto;
    padding-bottom: 8%;
  }

  .inputs{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    
  }

  .AuthPopUp button{
    width: 100%;
  }

  .UserPopUp button{
    width: 100%;
  }

  .AuthPopUp input{
    width: 100%;
    margin-bottom: 20px;
  }

  

  .identity-button{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    
  }

  .identity-logo{
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @media  (max-height: 500px) {
    .identity-logo{
      width: 10%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .identity-text{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .AuthPopUpMain{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
  }

  .top{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  .closeButton{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }


.closeButtonImage{
    width: 10px;
    height: auto;
}

.top{
  margin-top: 5%;
  padding-right: 8%;
  margin-bottom: 5%;
}

.confirmEmail{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}


.confirmEmail h3{
  text-align: center;
  margin-top: 0;
}

.confirmEmail h4{
  text-align: center;
  margin-top: 0;
}

.confirmEmail button{
  width: 100%;
  margin-bottom: 10px;
}

.confirmEmail input{
  width: 92%;
}


.userPopUpBody{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
}

.userDetailsDiv{
  border-bottom: solid 1px grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;

}

.userDetailsDiv p{
  margin-top: 8px;
  margin-bottom: 8px;
}

.userDetailsLabel{
  width: 27%;
  text-align: center;
  border-right: solid 2px grey;
}

.userDetailsContentText{
  width: 73%;
  text-align: center;
}

.mail{
  width: auto;
  height: 100px;
  border-radius: 15px;
  margin-bottom: 20px;

}