
  .FeedbackPopUp{
    position: absolute;
    width: 95%;
    height: auto;
    background-color: white;
    z-index: 10;
    border-radius: 50px;
    animation: slideUp 0.5s ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    max-height: 96%;
    overflow-y: auto;
    padding-bottom: 10%;
  }

  .identity-button{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    
  }

  .identity-logo{
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @media  (max-height: 500px) {
    .identity-logo{
      width: 10%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .identity-text{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .AuthPopUpMain{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
  }

  .topPopUp{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .closeButton{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }


.closeButtonImage{
    width: 10px;
    height: auto;
}


.confirmEmail{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}


.confirmEmail h3{
  text-align: center;
  margin-top: 0;
}

.confirmEmail h4{
  text-align: center;
  margin-top: 0;
}

.confirmEmail button{
  width: 100%;
  margin-bottom: 10px;
}

.confirmEmail input{
  width: 92%;
}


.userPopUpBody{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
}

.userDetailsDiv{
  border-bottom: solid 1px grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;

}

.userDetailsDiv p{
  margin-top: 8px;
  margin-bottom: 8px;
}

.userDetailsLabel{
  width: 27%;
  text-align: center;
  border-right: solid 2px grey;
}

.userDetailsContentText{
  width: 73%;
  text-align: center;
}

.mail{
  width: auto;
  height: 100px;
  border-radius: 15px;
  margin-bottom: 20px;

}

select, textarea, input[type="file"], button {
  padding: 7px;
  font-size: 17px;
  box-sizing: border-box;
  border-radius: 25px;
  border: solid 2px #1A1C33;
  background-color: #ffffff;
  color: #1A1C33;
  margin-bottom: 10px; /* Adds spacing between form elements */
}

/* Additional styling for the select element */
select {
  appearance: none; /* Removes default system styling */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M807.8 368.5L529.1 647.2c-12.5 12.5-32.8 12.5-45.3 0L216.2 368.5c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l245.3 245.3 245.3-245.3c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z"/></svg>'); /* Adds custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px; /* Ensures text doesn't overlap with the dropdown arrow */
  width: 100%;
  margin-bottom: 20px;
}

input{
  width: 100%;
  margin-bottom: 20px;
}

/* Styling for the textarea */
textarea {
  height: 100px; /* Sets a fixed height */
  padding: 10px; /* Adjusts padding for text alignment */
  resize: vertical; /* Allows vertical resizing */
  width: 100%;
}



form{
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}