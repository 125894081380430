.split-complete-container{
    position: absolute;
    width: 95%;
    height: 97%;
    background-color: white;
    z-index: 10;
    border-radius: 50px;
    top: 1%;
    animation: slideUp 0.5s ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    padding-top: 10px;
}

.split-complete-container p{
    z-index: 50;
    margin: 0 0 0 0;
    text-align: center;

}

.split-complete-container h1{
    z-index: 50;
    margin: 0 0 0 0;
    text-align: center;
    font-size: 75px;
}

.split-complete-container h2{
    z-index: 50;
    margin: 0 0 0 0;
    font-size: 35px;
    text-align: center;
}

.split-complete-container h3{
    z-index: 50;
    margin: 0 0 0 0; 
    font-size: 25px;
    text-align: center;
}

.split-complete-container h5{
    z-index: 50;
    margin: 0 0 0 0;
    text-align: center;
}

.split-complete-container button{
    z-index: 50;
    margin: 0 0 0 0;

}

.split-complete-top{
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 6%;
    padding-bottom: 15px;
    border-bottom: 1px solid gray;
}



.split-complete-final-payment{
    z-index: 50;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 21%;
    padding-bottom: 15px;
    border-bottom: 1px solid gray;
}

.split-complete-who-owes{
    z-index: 50;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 20%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}


.flipped-image {
    transform: rotateY(180deg);
  }

  
.split-complete-buy-me-coffee{
    z-index: 50;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 90%;
    height: 17%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}

.split-complete-bottom{
    z-index: 50;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 36%;

    
}

@media (max-width: 406px) {
    .split-complete-container h2{
        font-size: 32px;
    }
    

}


@media (max-width:  388px) {
    .split-complete-container h2{
        font-size: 30px;
    }
    
    .split-complete-container h3{

        font-size: 20px;

    }
}

@media (max-height:  650px) {
    .split-complete-container h2{
        font-size: 30px;
    }
    
    .split-complete-container h3{

        font-size: 20px;

    }
}





@media (max-width:  349px) {
    .split-complete-container h2{
        font-size: 26px;
    }
    
    .split-complete-container h3{

        font-size: 20px;

    }

    .split-complete-container h1{
        font-size: 55px;
    }
}

@media (max-width:  325px) {
    .split-complete-container h2{
        font-size: 26px;
    }
    
    .split-complete-container h3{

        font-size: 20px;

    }

    .split-complete-container h1{
        font-size: 55px;
    }
}

@media (max-width:  308px) {
    .split-complete-container h2{
        font-size: 24px;
    }
    
    .split-complete-container h3{

        font-size: 16px;

    }

    .split-complete-container h1{
        font-size: 50px;
    }
}